import React from "react";
import {apiHelper, graphHelper, s3Helper, ukApiHelper} from "../helper/apiHelper";

import dynamic from 'next/dynamic';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {DEFAULT, DEFAULT_LOCALES_LIST, US} from "../helper/constantHelper";

const Meta = dynamic(() => import('../components/meta/meta'));
const HomeComp = dynamic(() => import('../components/home'));

export default function Home(props) {
  const {
    meta,
    content,
    writers,
    reviews,
    sub_menu_data,
    recentArticles,
    // recentArticlesWriters,
    service_type_data,
    paper_types_data,
    display_pages_data,
    recentArticlesContentReadTime,
    writersNames,
    heroImageUrl,
    currLocale,
    linkLocale
  } = props;

  const { title, description, keywords, url_group, pageName, over_all_rating, no_of_reviews } = meta;
  
  return (
    <>
      <Meta title={title}
            description={description}
            keywords={keywords}
            urlCategory={url_group}
            currLocale={currLocale} />
      <HomeComp
        pageName={pageName}
        metaDescription={description}
        content={content}
        topWriters={writers}
        reviews={reviews}
        ratingData={{over_all_rating, no_of_reviews}}
        sub_menu_data={sub_menu_data}
        recentArticles={recentArticles}
        // recentArticlesWriters={writersNames}
        writersNames={writersNames}
        service_type_data={service_type_data}
        paper_types_data={paper_types_data}
        display_pages_data={display_pages_data}
        heroImage={heroImageUrl}
        currLocale={currLocale }
        linkLocale={linkLocale}
        recentArticlesContentReadTime={recentArticlesContentReadTime}
      />
    </>
  );
}

function calcWPM(str) {
  return ((str / 863) / 2).toFixed(0);
}

function getName(writers, writer_id) {
  if (writers) {
      return writers.filter(obj => {
          return obj.user_name.toLowerCase() == writer_id.toLowerCase()
      });
  } else {
      return;
  }
}

function validateName(name) {
  return (name && name.length > 0) ? name : [{ writer_name: 'Anonymous' }];
}

export async function getStaticProps(context) {

  const DEFAULT_META = process.env.defaultMeta;

  const locale = context.locale === DEFAULT ? US : context.locale;

  const subMenuData = await ukApiHelper(`subMenu`, 'GET', null, null, locale);
  const res = await ukApiHelper(`seoV1?page=index&is_live=${process.env.isApiLive}`, 'GET', null, null, locale);
  const resContent = await ukApiHelper(`servicesV1?page=index&is_live=${process.env.isApiLive}`, 'GET', null, null, locale);
  const resReviews = await ukApiHelper(`webReviewsV1?page=index`, 'GET', null, null, locale);
  const resRecentArticlesWriters = await ukApiHelper('articlePageWriters', 'GET', null, null, locale);
  const resHrefLang = await ukApiHelper('hreflang?page=index', 'GET', null, null, locale);
  const resHeroImage = await ukApiHelper('heroImage', 'GET', null, null, locale);

  const meta = res.data.status ? res.data.data : DEFAULT_META;

  const content = resContent.data.status ? resContent.data.data : null;

  const resWriters = await s3Helper(`static.cheapestessay.com/top5writers/${locale}-top5-writers.json`, 'GET', null, null);
  const writers = resWriters.data.status ? resWriters.data.data : null;
  
  const reviews = resReviews.data.status ? resReviews.data.data : null;

  const sub_menu_data = subMenuData.data.status ? subMenuData.data.data : null;

  const hrefLang = resHrefLang.data.status ? resHrefLang.data.data : null;

  const serviceTypeData = await apiHelper("displayservicetype", "GET", null, null);
  const service_type_data = serviceTypeData.data.status ? serviceTypeData.data.data : null;

  const paperTypesData = await apiHelper("displaypapertypes", "GET", null, null);
  const paper_types_data = paperTypesData.data.status ? paperTypesData.data.data : null;

  const displayPagesData = await apiHelper("displaypages", "POST", null, null);
  const display_pages_data = displayPagesData.data.status ? displayPagesData.data.data : null;

  const heroImage = resHeroImage.data.status ? resHeroImage.data.data : null;

  const heroImageUrl = heroImage && heroImage.length > 0 && heroImage[0].status !== 0 ? heroImage[0].url : '';

  const recentArticlesQuery = {query: `
    {
        articles(first: 3, where: {orderby: {field: DATE, order: DESC}}) {
            edges {
                node {
                    date
                    slug
                    title
                    featuredImage {
                        node {
                          sourceUrl
                        }
                    }
                    articleTags {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
    `
  };

  const resRecentArticles = await graphHelper(recentArticlesQuery);
  const recentArticles = await resRecentArticles.data;

  const recentArticleContentQuery = {query: `
  {
      articles (first: 3, where: {orderby: {field: DATE, order: DESC}}) {
        edges {
          node {
            content
          }
        }
      }
    }
  `
  };

  const resRecentArticlesContent = await graphHelper(recentArticleContentQuery);
  const recentArticlesContentData = await resRecentArticlesContent.data.data.articles;
  const recentArticlesContentReadTimeData = recentArticlesContentData && recentArticlesContentData.edges && recentArticlesContentData.edges.length > 0 && recentArticlesContentData.edges.map((articleItem) => {return calcWPM(articleItem.node.content.length)});
  const recentArticlesContentReadTime = JSON.stringify(recentArticlesContentReadTimeData);

  const recentArticlesWritersQuery = {query: `
  {
      articles (first: 3, where: {orderby: {field: DATE, order: DESC}}) {
        edges {
          node {
            authorFieldGroup {
              writerId
            }
          }
        }
      }
    }
  `
  };

  const resRecentArticleWriters = await graphHelper(recentArticlesWritersQuery);
  const resArticleWritersData = await resRecentArticleWriters.data.data.articles;
  const resArticleWritersIds = resArticleWritersData && resArticleWritersData.edges.length > 0 && resArticleWritersData.edges.map((articleWriterIdItem) => { return articleWriterIdItem.node.authorFieldGroup.writerId; });

  const recentArticlesWriters = await resRecentArticlesWriters.data.data;

  const resArticleWritersNames = resArticleWritersIds && resArticleWritersIds.map((articleWritersId) => {
    return articleWritersId && validateName(getName(recentArticlesWriters, articleWritersId))[0].writer_name;
  });

  const writersNames = resArticleWritersNames && JSON.stringify(resArticleWritersNames);

  if (context.query && (context.query.code || context.query.discount_code)) {
    return {
      redirect: {
        destination: (context.locale === DEFAULT ? '' : `/${locale}`) + `/order?${context.query.code ? `code=${context.query.code}` : `discount_code=${context.query.discount_code}`}`
      },
      ...(await serverSideTranslations(context.locale, ['common']))
    }
  } else {
    const linkLocale = DEFAULT_LOCALES_LIST.includes(locale) ? false : locale;
    return {
      props: {
        meta,
        content,
        writers,
        reviews,
        sub_menu_data,
        recentArticles,
        // recentArticlesWriters,
        writersNames,
        recentArticlesContentReadTime,
        currLocale: context.locale,
        linkLocale,
        service_type_data,
        paper_types_data,
        display_pages_data,
        hrefLang,
        heroImageUrl,
        ...(await serverSideTranslations(locale, ['common']))
      },
      revalidate: 10
    }
  }
}